import { Form, Formik } from "formik"
import React from "react"

import FormCheckbox from "../../Elements/Form/FormCheckbox"
import ActionButtons from "../../Elements/ActionButtons"
import Section from "../../Elements/Section"

const SummaryConsent = ({
  state,
  loading,
  dispatch,
  pageContext,
  handleSubmit,
}) => {
  const TERMS_AND_CONDITIONS_BLURB = `<span>I agree to the <a href="/terms-and-conditions" target="_blank" class="has-text-secondary has-text-weight-bold is-underlined"><b>Terms and Conditions</b></a>.</span>`

  return (
    <Formik
      initialValues={{ consentAndAuthorization: [] }}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form>
          <Section title="Consent and Authorization" isSectionRequired={true}>
            <div className="mb-3">
              <FormCheckbox
                name="consentAndAuthorization"
                values={values.consentAndAuthorization}
                options={[TERMS_AND_CONDITIONS_BLURB]}
                onChange={(event) =>
                  dispatch({
                    type: "SAVE_AGREE_CONSENT",
                    payload: event.target.checked
                      ? [TERMS_AND_CONDITIONS_BLURB]
                      : [],
                  })
                }
                isRequired
              />
            </div>
          </Section>

          <ActionButtons
            back={{
              label: (
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.0664 26.5599L11.3731 17.8666C10.3464 16.8399 10.3464 15.1599 11.3731 14.1333L20.0664 5.43994"
                    stroke="#EE7423"
                    stroke-width="2.66667"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ),
              link: pageContext.backPath,
            }}
            submit={{
              loading,
              label: "Submit",
            }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default SummaryConsent
